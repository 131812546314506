.App {
  display: grid;
  grid-template-columns: 5% auto 5%;
}

.App-header {
  margin-top: 2px;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  color: white;
}

