.outer {
  display: flex;
  flex-direction: column;
}

.inputBlock {
  display: grid;
  grid-column-gap: 0.5em;
  grid-template-columns: 10% minmax(150px, 10fr) 1fr 10%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.input {
  font-size: larger;
  background: #fff9ea;
  padding: 0.2em;
  outline: #8e8e8e auto 1px;
}
.input:focus {
  outline: black auto 1px;
}

.inputError,
.inputError:focus {
  outline: red auto 1px;
}

.checkboxBlock {
  display: grid;
  grid-column-gap: 0.5em;
  grid-template-columns: 10% auto 10%;
  margin-top: 1em;
  margin-bottom: 1em;

  max-height: 300px;
  overflow-y: hidden;
  transition: max-height 0.5s ease-in-out;
}

.checkboxBlock.isHidden {
  max-height: 0px;
}

.checkboxes {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}

.checkboxGroup {
  flex-grow: inherit;
  border: 1px solid #ffda84;
  padding: 1em;
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

.queryButton {
  padding: 0.2em;
  font-size: larger;
  background: #fff9ea;
  outline: #8e8e8e auto 1px;
  cursor: pointer;
  user-select: none;
}

.pulse {
  animation: pulse 0.7s infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}

@keyframes pulse {
  0% {
    color: #001f3f;
  }
  100% {
    color: #ff4136;
    letter-spacing: -1px;
  }
}

.pagingButton {
  @extend .queryButton;
  padding: 0 0.2em;
}

.queryButton:hover {
  background: #e9e4d8;
}
.queryButton:active {
  background: #fff;
}
.queryButton:focus {
  outline: black auto 1px;
  background: #d9e4d8;
}

.queryError {
  color: red;
}

.fetchError {
  margin: 1em;
  text-align: center;
  color: red;
  font-weight: 600;
}

.result {
  border: 1px solid #ffda84;
  padding: 0.4em;
  margin-bottom: 0.4em;
}

.resultHeadwordContainer {
  display: flex;
}

.resultHeadword {
  font-weight: 600;
}

.resultGrammar {
  font-weight: 300;
  margin-left: 2em;
  font-style: italic;
}

.resultPage {
  margin-left: auto;
  font-weight: 300;
  font-size: smaller;
}

.resultsContainer {
  display: flex;
  padding: 0.4em 0;
}

.resultsCount {
  padding-bottom: 0.4em;
}

.paging {
}

.pagingButton {
}

.resultWordforms {
}

.grammarAbbrv {
  font-weight: 300;
  font-style: italic;
}

.resultEntry {
  // text-indent: 4em;
}

.resultEntryPad {
  min-width: 4em;
}
.resultEntryMain {
  display: flex;
}

.searchTermInResults {
  background-color: yellow;
}

@media all and (max-width: 500px) {
  .inputBlock {
    display: flex;
    flex-direction: column;
  }
  .checkboxGroup {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .checkboxBlock {
    display: flex;
    flex-direction: column;
  }
  .checkboxes {
    flex-direction: column;
  }
  .resultEntryPad {
    min-width: 1em;
  }
}


//
// custom checkbox:
//
input[type="checkbox"] + label::before {
  content: "　";
  position: relative;
  display: inline-block;
  margin-left: 10px;
  margin-right: 7px;
  width: 22px;
  height: 20px;
  background: white;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 0 0 2px 0;
}

input[type="checkbox"]:checked + label::before {
  content: "✓";
  padding: 0 0 2px 0;
}

input[type="checkbox"]:focus + label::before {
  outline: #5d9dd5 solid 1px;
  box-shadow: 0 0px 8px #5e9ed6;
}

input[type="checkbox"] {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
